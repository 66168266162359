<template>
  <div id="directors">
    <div class="block-orange"></div>
    <app-navbar></app-navbar>
    <div class="yellowWrap">
      <img class="img-fluid" src="@/assets/yellowWrap.png" alt="whiteBallone">
    </div>
    <div class="whiteBalls">
      <img class="img-fluid" src="@/assets/whiteBalls.png" alt="whiteBallone">
    </div>
    <div class="container directorsBlock">
      <h2 class="text-center">{{ "Directors.label" | staticTranslation }}</h2>
      <div class="row">
        <div class="col-lg-4 no-gutters" v-for="director in directors" v-bind:key="director.id">
          <a v-bind:href="'/program/director/' + director.id">
            <img class="img-fluid cropped-img" :src="getInterpretImgUrl(director.id)" alt="Foto"></a>
          <h4>{{ director.name | translation }}</h4>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import navbar from "@/components/base/navbar";
import axios from "@/api/axiom-instance";
import footer from "@/components/base/footer";
import config from "@/config";

export default {
  name: "Directors",
  components: {
    'app-navbar': navbar,
    'app-footer': footer
  },
  data() {
    return {
      directors: []
    }
  },
  created() {
    this.load()
  },
  methods: {
    load: function () {
      axios
          .get("/director/list/visible")
          .then((success) => {
            this.directors = success.data
          }).catch((failed) => {
        console.log(failed)
      })
    },
    getInterpretImgUrl: function (id) {
      return config.API_URL + "/image/preview?type=directors&name=" + id
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 3.5em;
  padding-top: 3em;
  padding-bottom: 50px;
  font-family: "Transcript Pro";
}

h2 {
  font-size: 2.5em;
  padding-top: 3em;
  padding-bottom: 3em;
  font-family: "Transcript Pro";
}

h4 {
  padding: 6px;
  position: absolute;
  font-family: "Transcript Pro";
  font-size: 1.1em;
  text-align: left;
  background-color: #e7e7e6;
  color: #152326;
  bottom: 0;
  margin: 0;
}

h5 {
  color: black;
  padding-right: 10px;
  display: inline-block;
  font-family: "Transcript Mono";
}

p {
  font-family: "Transcript Mono";
}

.col-lg-4 {
  padding-left: 0;
  padding-right: 0;
}

.cropped-img {
  height: 300px;
  width: auto;
  object-fit: cover;
}

.block-orange {
  background-color: #ed764e;
  position: absolute;
  text-align: end;
  top: 45%;
  right: 0;
  height: 76vh;
  width: 88%;
}

.whiteBalls {
  width: 6%;
  position: absolute;
  top: 25%;
  left: 5%;
}

.yellowWrap {
  width: 12%;
  position: absolute;
  text-align: end;
  right: 3%;
  top: 90vh;
}

.directorsBlock {
  padding-bottom: 4em;
  min-height: 121vh;
}

#directors {
  background-color: #f3ba53;
}

@media (min-width: 1200px) {
  .container {
    max-width: 900px;
  }
}
</style>
